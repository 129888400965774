<template>
  <div class="posts-by-engagement-entry">
    <Content
      class="wrapper"
      title="Top Posts By Engagement"
      description="Review your top posts published during the selected time period, based on the post’s lifetime performance."
      :has-content-padding="false"
    >
      <div class="filters">
        <div class="social-accounts">
          <router-link
            :class="['account', { disabled: account.disabled }]"
            v-for="account in accounts"
            :key="account.title"
            :to="getSocialAccountFilterRoute(account)"
            exact-active-class="active"
          >
            {{ account.title }}
          </router-link>
        </div>
        <SortFilter :filters="filters" v-model="currentFilter"></SortFilter>
      </div>
      <div v-if="$apollo.queries.postContents.loading" class="bg-white shadow-sm rounded text-center py-5 loader">
        <spinner size="4" />
      </div>
      <div
        class="posts"
        v-else-if="filteredPostContents && filteredPostContents.length > 0 && !$apollo.queries.postContents.loading"
      >
        <SinglePostPreview
          v-for="postContent in filteredPostContents"
          :key="postContent.id"
          :post-content="postContent"
        ></SinglePostPreview>
      </div>
      <div
        v-else-if="filteredPostContents && filteredPostContents.length < 1 && !$apollo.queries.postContents.loading"
        class="empty"
      >
        <div class="bg-white rounded text-center p-5">
          <img class="pt-3" width="300" src="@/assets/images/lost.svg" alt="" />
          <h4 class="pt-5">No posts here yet.</h4>
        </div>
      </div>
    </Content>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { get, isNaN } from 'lodash';
import Content from '~/components/Reports/partials/Content';
import SortFilter from '@/components/Reports/partials/SortFilter';
import SinglePostPreview from '~/components/SinglePostPreview';
import { REPORT_QUERY } from '~/graphql/queries';

const workspaceNamespace = createNamespacedHelpers('workspace');

export default {
  data() {
    return {
      filters: [
        {
          title: 'Top 5',
        },
        {
          title: 'Top 10',
        },
        {
          title: 'Top 20',
        },
        {
          title: 'Bottom 5',
        },
        {
          title: 'Bottom 10',
        },
        {
          title: 'Bottom 20',
        },
      ],
      currentFilter: null,
      accounts: [
        { title: 'overall' },
        { title: 'facebook' },
        { title: 'twitter' },
        { title: 'linkedin', disabled: true },
      ],
      postContents: [],
      activeStatus: 'all',
    };
  },
  components: {
    Content,
    SortFilter,
    SinglePostPreview,
  },
  computed: {
    getSocialAccountFilterRoute() {
      return account => {
        return { ...this.$route, query: { ...this.$route.query, social: account.title } };
      };
    },
    currentSocialAccount() {
      return get(this.$route, 'query.social');
    },
    filteredPostContents() {
      const currentSocialAccount = this.currentSocialAccount;
      if (currentSocialAccount === 'overall') return this.postContents;
      return this.postContents.filter(postContent => postContent.platform === currentSocialAccount);
    },
    getLimit() {
      const filter = this.getFilter;
      if (!filter) return 15;

      const limit = parseInt(filter[1], 10);
      if (isNaN(limit)) return 15;

      return limit;
    },
    getFilter() {
      const filter = get(this.currentFilter, 'title');
      if (!filter) return;

      return filter.split(' ');
    },
    getDirection() {
      const filter = this.getFilter;
      if (!filter) return 'desc';

      const direction = String(filter[0]).toLowerCase();
      if (direction === 'bottom') return 'asc';

      return 'desc';
    },
    ...workspaceNamespace.mapState({
      workspace: state => state.current,
    }),
  },

  created() {
    if (!this.currentSocialAccount) {
      this.$router.push(this.getSocialAccountFilterRoute({ title: 'overall' }));
    }
  },

  apollo: {
    postContents: {
      query: REPORT_QUERY,
      variables() {
        return {
          name: 'overview.top-posts-by-engagement',
          workspace: this.workspace.id,
          limit: this.getLimit,
          direction: this.getDirection,
        };
      },
      update(data) {
        const postContents = get(data, 'reports.post_contents');
        return postContents;
      },
    },
  },

  methods: {
    async loadMore() {
      this.offset = this.offset + 15;
      this.loadingMore = true;

      // Fetch more data and transform the original result
      await this.$apollo.queries.postContents.fetchMore({
        // New variables
        variables: {
          offset: this.offset,
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newContents = fetchMoreResult.singlePosts.posts;
          const hasMore = fetchMoreResult.singlePosts.hasMore;
          const total = fetchMoreResult.singlePosts.total;

          this.loadMoreEnabled = hasMore;

          return {
            singlePosts: {
              __typename: previousResult.singlePosts.__typename,
              // Merging the tag list
              posts: [...previousResult.singlePosts.posts, ...newContents],
              total,
              hasMore,
            },
          };
        },
      });

      this.loadingMore = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.posts-by-engagement-entry {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;

  ::v-deep {
    > .wrapper {
      width: 100%;

      > .content {
        margin: 30px 0;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
        align-items: stretch;
        flex-grow: 1;

        > .filters {
          display: flex;
          width: 100%;
          padding: 0 25px;
          flex-wrap: nowrap;

          > * {
            display: inline-flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
          }

          > .social-accounts {
            margin: 0 30px 0 0;
            max-width: 85%;
            justify-content: space-between;

            > .account {
              position: relative;
              padding: 10px 30px 12px;
              font-size: 18px;
              text-transform: capitalize;
              color: #868686 !important;
              text-decoration: initial;

              &:not(:nth-of-type(1)) {
                margin: 0 0 0 30px;
              }

              &::after {
                display: inline-flex;
                position: absolute;
                flex-grow: 1;
                content: '';
                height: 4px;
                width: 100%;
                bottom: 0;
                left: 0;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                background-color: transparent;
                transition: all 0.3s ease-in-out;
              }

              &:not(.disabled) {
                &:not(.active):hover,
                &.active {
                  &::after {
                    background-color: #93a2f9;
                  }
                }
              }

              &.disabled {
                pointer-events: none;
              }
            }
          }

          > .filter-sort-wrapper {
            margin: 0 0 0 auto;
          }
        }

        > .posts {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          align-content: flex-start;

          > .single-post-entry {
            width: 100%;

            > .wrapper {
              border-radius: initial;
              background-color: #f4f4f4;
              width: 100%;

              > .image {
                width: 180px;
                height: 200px;
              }

              > .data {
                margin: 0 0 0 20px;

                > .info {
                  width: 300px;
                }
              }

              > .analytics {
                > .item {
                  > .title {
                    background-color: #fbfbfb;
                  }

                  > .value {
                    background-color: #ffffff;
                  }
                }
              }
            }

            &:nth-of-type(even) {
              > .wrapper {
                background-color: #ffffff;

                > .analytics {
                  > .item {
                    > .value {
                      background-color: #f4f4f4;
                    }
                  }
                }
              }
            }

            &:last-of-type {
              > .wrapper {
                border-radius: 15px;
                border-top: {
                  right-radius: initial;
                  left-radius: inital;
                }
              }
            }
          }
        }

        > .loader,
        > .empty {
          width: 100%;
          min-height: 500px;
          justify-content: center;
          align-items: center;
          display: flex;
          background-color: #ffffff;
          box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
</style>
